@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&display=swap');

html {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

body {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer {
    width: 90%;

    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 3;

    display: flex;
    justify-self: center;
    justify-content: space-between;

}

a {
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
    color: #000000;
}

a:hover {
    color: #ffffff;
}

#errorLink {
    text-decoration: underline;
    cursor: pointer;
    color: #de1a1a;
}

#errorLink:hover {
    color: #542344;
}

@media screen
and (max-width: 1800px) {
    .container{
        transform: scale(0.8);
    }
}

@media screen
and (max-width: 1140px) {
    .container{
        transform: scale(0.5);
    }
}

button {
    background-color: #ffffff;
    box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 0;

    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;

    border: none;

    font-family: 'Courier Prime', monospace;
    font-size: 1.5em;
    color: #000000;
}


button:hover {
    cursor: pointer;
    box-shadow: 0 6px 6px 0px rgba(0, 0, 0, 0.5);
}

button:focus {
    outline: none;
    box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.5);
}

body {
    background-color: #ebb733;
    font-family: 'Courier Prime', monospace;
    font-size: 18px;
}

h1 {
    text-align: center;
    font-size: 6em;
    padding: 0;
    white-space: nowrap;
    margin:0;
    padding:0;
}

img {
    width: 3em;
}

.hidden {
    opacity: 0;
}

.redText {
    color:#de1a1a;
}

.flex-row {
    display: flex;
}

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

        .main-grid {
            display: grid;
            grid-template-columns: 420px 2fr;
            grid-template-rows: 594px 3em 1fr;
            grid-gap: 10px;
            
            max-width: 1140px;
            height: 660px;

            padding: 0;

            justify-content: center;
            align-content: center;
        }

            .poster-preview {
                grid-row-start: 1;
                grid-row-end: 2;
                grid-column-start: 1;
                grid-column-end: 2;

                box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.25);



                /*For debugging
                border-style: solid;
                background-color: white;
                */
            }
                .align-bottom{
                    display: flex;
                    flex-direction: column;
                    height: 0;
                    width: 80%;
                    justify-content: flex-end;

                    position:relative;
                    top: 20%;
                    left: 10%

                }
                .poster-title {
                    align-items: center;
                    text-align: center;
                    font-size: 20pt;
                    font-weight: 600;
                    text-transform: uppercase;

                    overflow-wrap: break-word;
                }

                .poster {
                    background-color: white;
                    width: 100%;
                    height: 100%;
                }
                .poster-quote {
                    left: 21%;
                    width: 58%;
                    position: relative;
                    bottom: 19.5%;
                    font-size: 9pt;
                    text-align: center;
                    font-weight: 600;
                }
                .author {
                    left: 21%;
                    width: 58%;
                    margin-top: -2.5%;
                    position: relative;
                    bottom: 19.5%;
                    font-size: 9pt;
                    text-align: center;
                    font-weight: 300;
                }

            .customise {
                grid-row-start: 1;
                grid-row-end: 2;
                grid-column-start: 2;
                grid-column-end: 3;

                display: grid;
                padding: 3em 2em 2em 2em;
                grid-gap: 10px;
                grid-template-rows: 0.5fr 1fr 1fr 5fr 1fr;
                grid-template-columns: 1fr 1fr 1fr 1fr;

                background-color: #ffffff;
                box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.25);
            }
                .box {
                    padding: 1em;
                }

                .error {
                    grid-row-start: 1;
                    grid-row-end: 2;
                    grid-column-start: 1;
                    grid-column-end: 5;

                    background-color: white;
                    border-style: solid;
                    border-width: 2px;
                    border-color: #de1a1a;

                    font-size: 0.75em;
                    padding: 0.75em;
                    color: #de1a1a;
                }

                .colours [type="radio"] {
                    opacity: 0;
                    position: fixed;
                    width: 0;
                }

                .colours [type="radio"] + label{
                    cursor: pointer;
                }

                .colours [type="radio"]:checked + label{
                    box-sizing: content-box;
                    border-width: 4px;
                    cursor: default;
                }

                .box-preselected-colours{
                    grid-row-start: 2;
                    grid-row-end: 3;
                    grid-column-start: 1;
                    grid-column-end: 3;

                    background-color: #ebb733;
                    display: flex;
                    align-items: center;
                    padding: 1em;

                }
                    
                    .preselected-colour{
                        background-color: white;
                        width: 30px;
                        height: 30px;

                        border-style: solid;
                        border-width: 2px;
                        box-sizing: border-box;

                        margin: 0 0.5em 0 0;
                    }
                        .white{
                            background-color: #ffffff;
                        }
                        .black{
                            background-color: #000000;
                        }
                        .pink{
                            background-color: #fad4d8;
                        }
                        .purple{
                            background-color: #542344;
                        }
                        .yellow{
                            background-color: #ecd444;
                        }
                        .grey{
                            background-color: #7d7c7a;
                            margin-right: auto;
                        }
                    
                    .colour-code {
                        font-size: inherit;
                        font-family: inherit;
                        caret-color: #ebb733;
                        box-sizing: border-box;

                        padding: 0.5em 0.5em 0.5em 0.5em;

                        outline: none;
                        border: none;
                        resize: none;

                        height: 30px;
                        width: 110px;

                        border-style: solid;
                        border-width: 2px;

                    }
                        .colour-code:focus {
                            outline: none;
                            border: none;

                            outline-style: solid;
                            outline-width: 4px;
                            outline-color: black;
                        }

                        .colour-code-selected {
                            outline: none;
                            border: none;

                            outline-style: solid;
                            outline-width: 4px;
                            outline-color: black;
                        }


                .box-text-colours{
                    grid-row-start: 2;
                    grid-row-end: 3;
                    grid-column-start: 3;
                    grid-column-end: 4;

                    background-color: #ebb733;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 1em 1em 1em 1em;
                }

                .preselected-colour-text {
                    width: 30px;
                    height: 30px;

                    border-style: solid;
                    border-width: 2px;
                    box-sizing: border-box;
                    margin: 0;
                }

                .box-nerd{
                    grid-row-start: 2;
                    grid-row-end: 3;
                    grid-column-start: 4;
                    grid-column-end: 5;

                    background-color: #ebb733;
                }

                    .nerd {
                        box-sizing: border-box;
                        cursor: pointer;
                        width: 100%;
                        height: 100%;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 600;

                    }

                        input[type="checkbox"] {
                            opacity: 0;
                            position: fixed;
                            width: 0;
                        }

                        input[type="checkbox"]:checked + .nerd{
                            border-style: solid;
                            border-color: black;
                            border-width: 4px;
                        }

                        .size {
                            background-color: white;
                            cursor: pointer;
                        }

                        .size input[type="radio"] {
                            opacity: 0;
                            position: fixed;
                            width: 0;
                        }

                        .size input[type="radio"]:checked + label{
                            outline-style: solid;
                            outline-color: black;
                            outline-width: 4px;
                            cursor: default;

                            border-style: none;
                        }

                .box-activity-input{
                    grid-row-start: 3;
                    grid-row-end: 4;
                    grid-column-start: 1;
                    grid-column-end: 2;

                    background-color: #ffffff;
                    border-color: #000000;
                    border-style: dashed;
                    border-width: 2px;

                    outline: none;
                    resize: none;
                    font-family: inherit;
                    font-size: 1.25em;
                    caret-color: #ebb733;
                }

                    .box-activity-input:focus{
                        border-color: #ebb733
                    }

                .box-size{
                    background-color: #ebb733;
                    grid-row-start: 3;
                    grid-row-end: 4;
                    grid-column-start: 2;
                    grid-column-end: 5;

                    display: grid;
                    padding: 0em 0.75em 0.5em 0.75em;

                    align-items: center;
                }
                    p {
                        color: white;

                        grid-row-start: 1;
                        grid-row-end: 2;
                        grid-column-start: 1;
                        grid-column-end: 3;

                        padding: 0;
                        margin: 0.5em 0 0.25em 0;
                    }

                    .european {
                        grid-row-start: 2;
                        grid-row-end: 3;
                        grid-column-start: 1;
                        grid-column-end: 2;

                        margin: 0 0.5em 0 0;
                        border-style: solid;
                        border-width: 2px;

                        padding: 0.5em;
                        height: 1em;
                        font-size: inherit;

                        text-align:center;
                        font-weight: 600;
                    }

                    .american {
                        grid-row-start: 2;
                        grid-row-end: 3;
                        grid-column-start: 2;
                        grid-column-end: 3;

                        margin: 0 0 0 0.25em;
                        border-style: solid;
                        border-width: 2px;

                        padding: 0.5em;
                        height: 1em;
                        font-size: inherit;

                        text-align: center;
                        font-weight: 600;
                    }

                .box-quote-input{
                    grid-row-start: 4;
                    grid-row-end: 5;
                    grid-column-start: 1;
                    grid-column-end: 5;

                    background-color: #ffffff;

                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    justify-content: flex-end;
                    margin: 0 0 0.5em 0;
                }
                    #quote {
                        flex-grow: 1;
                        outline: none;
                        resize: none;
                        font-family: inherit;
                        font-size: 1.25em;
                        caret-color: #ebb733;

                        border-style: dashed;
                        border-color: #000000;
                        border-width: 2px;
                    }

                    #quote:focus{
                        border-color: #ebb733;
                    }

                    #quoteLength {
                        margin: -1.5em 0.5em 0 auto;
                    }

                .box-author-input{
                    grid-row-start: 5;
                    grid-row-end: 6;
                    grid-column-start: 1;
                    grid-column-end: 5;

                    background-color: #ffffff;
                    border-style: dashed;
                    border-width: 2px;
                    border-color: #000000;

                    outline: none;
                    resize: none;
                    font-family: inherit;
                    font-size: 1.25em;
                    caret-color: #ebb733;
                }

                .box-author-input:focus{
                    border-color: #ebb733;
                }

            .hidden {
                display: none;
            }